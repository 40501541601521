.bootstrap-select {
  > .dropdown-toggle {
    background-color: $input-bg;
    border: $input-border-width solid $input-border-color;

    // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
    @include border-radius($input-border-radius, 0);

    @include box-shadow($input-box-shadow);
    @include transition($input-transition);

    &:focus {
      outline: none !important;
    }
  }
}
