// Color system
$black: #000;
$white: #fff;

$gray-100: #f5f7fb;
$gray-300: #dde0e6;
$gray-600: #bcbfc5;
$gray-800: #7e828f;
$gray-850: #8d93ab;
$gray-900: #2e374e;

$grays: (
  "100": $gray-100,
  "300": $gray-300,
  "600": $gray-600,
  "800": $gray-800,
  "850": $gray-850,
  "900": $gray-900
);

$purple: #6d004d;
$purple-light: #f0e5ed;
$purple-ultra-light: #faf7f9;
$red: #ee535a;

$colors: (
  "purple-light": $purple-light,
  "purple-ultra-light": $purple-ultra-light
);

$primary: $purple;

// Options
//$enable-responsive-font-sizes: true;

// Body
$body-bg: $gray-100;
$body-color: $gray-900;

// Links
$link-hover-color: $body-color;

$grid-gutter-width: 30px;

// Spacer
$spacer: $grid-gutter-width / 2;

// Paragraphs
$paragraph-margin-bottom: $spacer * 2;

// Nav

// Components
$transition-collapse: height .35s ease, min-height .35s ease;

// Buttons + Forms
$input-placeholder-color: $gray-600;


$input-reverse-bg: $gray-100;
$input-reverse-border-color: $gray-100;

$input-reverse-color: $body-color;

$input-reverse-placeholder-color: $input-placeholder-color;

// Typography
//$font-family-sans-serif: "Gotham", -apple-system, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-size-base: 1rem;
$line-height-base: 1.7;

$headings-margin-bottom: $spacer * 2;
$headings-color: $gray-900;

$h1-font-size: px2rem(20);
$h2-font-size: px2rem(16);
$h3-font-size: px2rem(14);

// Modals

$modal-backdrop-bg: $white;

// Figures
$figure-caption-font-size: 81.25%;
$figure-caption-color: $gray-600;

// Breadcrumbs

$breadcrumb-padding-x: $spacer;
$breadcrumb-item-padding: .25rem;

$breadcrumb-margin-bottom: null;

$breadcrumb-bg: transparent;

$breadcrumb-border-radius: null;

// Utilities
$displays: none, inline, inline-block, block, flex;

$border-radius:               px2rem(5px);
$border-radius-lg:            px2rem(10px);
$border-radius-sm:            px2rem(2.5px);

$box-shadow-sm: 0 .125rem .25rem rgba($black, .075);
$box-shadow: 0 0 $spacer rgba(#e3e3e3, .9);
$box-shadow-lg: 0 1rem 3rem rgba($black, .175);

// Cards

$card-spacer-x: $spacer * 2;
$card-border-width: 0;
$card-border-radius: px2rem(10);

// Printing
$print-page-size: a4;
