.widget-chart {

  .icon-wrapper {}

  .widget-numbers {
    display: block;
    margin: 1rem auto;
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1;
  }

  .widget-subheading {
    display: block;
    margin: -.5rem 0 0;
    opacity: .6;
  }

  .widget-description {
    margin: 1rem 0 0;
  }

  .widget-numbers + .widget-description,
  .widget-numbers + .widget-subheading {
    margin-top: -.5rem;
  }
}
