/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function px2rem($px, $font-base-rem:$font-size-base, $font-base-px: 16) {
  $font-base-size: strip-unit($font-base-px) * strip-unit($font-base-rem);

  @return (strip-unit($px) / $font-base-size) * 1rem;
}
