#header {
  padding: 10px $spacer;
  color: $white;

  svg.wineadvisor-logo {
    height: 30px;

    g {
      fill: $white;

      &.advisor-part {
        opacity: .95;
      }
    }
  }
}
