.nav-wrapper {
  .nav-text {
    display: none;

    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }

  &.slim {
    @include media-breakpoint-up(md) {
      .nav-text {
        display: none;
      }
    }
  }
}

.nav-sep {
  margin: $nav-link-padding-y $nav-link-padding-x;
}

[data-toggle="hide-nav"] {
  .fa-arrow-to-right { display: none; }

  .slim & {
    .fa-arrow-to-right { display: inline-block; }
    .fa-arrow-to-left { display: none; }
  }
}

#nav-top {
  color: $white;

  .nav-link {
    color: $white;

    @include hover-focus () {
      opacity: .9;
    }
  }
}

#nav-sidebar {
  min-height: 100vh;
  transition: min-width .35s ease-in-out, max-width .35s ease-in-out;

  @include media-breakpoint-up(md) {
    &:not(.slim) {
      min-width: 250px;
      max-width: 250px;
    }
  }

  .nav-link {
    color: $body-color;

    .fas {
      width: 1.5rem;
      text-align: center;
    }

    &.active {
      background: $purple-light;
    }

    @include hover-focus() {
      color: $primary;
    }
  }
}
